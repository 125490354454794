.navbar-logo {
  pointer-events: none;
  height: 50px;
}

@media (prefers-reduced-motion: no-preference) {
  .navbar-logo {
    animation: navbar-logo-spin infinite 20s linear;
  }
}


@media only screen and (max-width: 767px) {
  .medium-line {
      font-size: 16px;
  }

  .display-66{
    font-size: 18px;
  }

  .need-help {
    color: #2D2D2D;
    font-family: 'Avenir LT Pro Medium';
    font-size: 15px;
  }

  .row-input {
    font-size: 20px;
    color: black;
    font-weight: 700;
    padding: 0 15px;
    height: 45px;
    margin-top: 15px;
    width: 100%;
}
}