body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face { */
  /* font-family: 'Caecilia LT Std'; */
  /* src: local('Caecilia LT Std'), url(./fonts/CaeciliaLTStd/Caecilia\ LT\ Std\ 55\ Roman/Caecilia.otf) format('opentype'), */
   /* local('Caecilia LT Std'), url(./fonts/CaeciliaLTStd/CaeciliaLTPro55Roman.ttf) format('ttf'); */
  
/* } */

.bg-dark-2{
  background-color: #939393 !important;
}

.custom-select-container {
  position: relative;
  width: 200px;
}

.selected-option {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #ccc;
}

.selected-option.open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.options-list {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: white;
  z-index: 111111;
}

.options-list li {
  padding: 10px;
  cursor: pointer;
}

.options-list li.active{
  background-color: #C80000;
  color: white;
}
 
.options-list li:hover{
  background-color: #C80000 !important;
  color: white;
}

.options-list li:hover {
  background-color: #f0f0f0;
}


.text-black{
  color: black;
}

@font-face {
  font-family: 'Avenir LT Pro';
  src: local('Avenir LT Pro'), url(./fonts/AvenirLTProBlack.otf) format('opentype');
}
@font-face {
  font-family: 'Avenir LT Pro Medium';
  src: local('Avenir LT Pro Medium'), url(./fonts/AvenirLTProMedium.otf) format('opentype');
}
@font-face {
  font-family: 'Avenir LT Pro Light';
  src: local('Avenir LT Pro Light'), url(./fonts/AvenirLTProLight.otf) format('opentype');
}

p{
  font-family: 'Avenir LT Pro Light';
}

.av-font-light{
  font-family: 'Avenir LT Pro Light';
}

.ca-font{
  font-family: 'Avenir LT Pro';
}

.label8{
  color: #000;
font-family: 'Avenir LT Pro';
font-size: 32px;
font-style: normal;
font-weight: 700;
line-height: normal;
letter-spacing: 0.64px;
}

.label9{
  color: #000; 
font-family: 'Avenir LT Pro Medium';
font-size: 15px; 
font-weight: 400;
line-height: normal;
letter-spacing: 0.32px;
}

.label10{
  font-size: 15px;
}

.custom-radio {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px; /* Adjust font size as needed */
  color: #333; /* Set label color */
}

input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-btn {
  display: inline-block;
  width: 22px; 
  height: 22px;
  border-radius: 50%; 
  border: 2px solid #E5E5E5; 
  margin-right: 8px; 
  color: var(--greys-foreground-text-body-copy, #2D2D2D);
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.4px;
text-transform: capitalize;
}

input[type="radio"]:checked + .radio-btn {
  background-color: #C80000;
}


.av-font-medium{
  font-family: 'Avenir LT Pro Medium';
}

.medium-line{
  color: #000;
  font-family: 'Avenir LT Pro Medium';
font-size: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.48px;
}

.need-help{
  color: #2D2D2D;
font-family: 'Avenir LT Pro Medium';
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.4px;
text-decoration-line: underline;
text-transform: capitalize;
}

button {
  border-radius: 2px;
  border: 1px solid var(--greys-foreground-text-accessible-grey, #939393);
  background: var(--greys-foreground-text-accessible-grey, #939393);
  box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
  color: #FFF;
  font-family: "Avenir LT Pro Medium" !important;
  font-size: 24px !important;
  font-style: normal;
  padding: 12px; 
  line-height: 24px;
}

.display-55 {
  color: #000;
  font-family: 'Avenir LT Pro';
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 116%;
  letter-spacing: 0.64px;
}

select{
  border: 2px solid var(--Greys-Backgrounds-DCDCDC, #DCDCDC);
   background: var(--Greys-Backgrounds-FAFAFA, #FAFAFA);
   display: flex;
height: 54px;
align-items: center;
gap: 24px;
}

.box-1{
  border: 1px solid #323232;
}


/* selectoption{
  background: unset;
  padding: 10px !important;
} */

.red-a{
  color:#C80000 !important;
}

option {
  padding: 10px; 
  border-radius: 0 !important;
}

select option:focus,option:hover,option:visited {
  background: red linear-gradient(0deg, red 0%, red 100%) !important;
  color: white !important;
}

.options li:hover {
  background-color:red;
  color:white;
}

button.active{
  border-radius: 2px;
border: 1px solid var(--greys-foreground-text-accessible-grey, #939393);
background: #C80000;
box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.16);
}

.bb-1{
  border-bottom: 1px solid var(--Core-ACCA-black, #000);
}

.plan-line-1{
  color: #3F3F3F;
font-family: 'Avenir LT Pro';
font-size: 20px;
font-style: normal;
font-weight: 750;
line-height: normal;
text-transform: lowercase;
}
.plan-amount{
  color: #C80000;
/* font-family: Avenir LT Pro; */
font-size: 32px;
font-style: normal;
font-weight: 750;
line-height: normal;
text-transform: capitalize;
}

.border-1px{
  border: 1px solid #494949;
}

.download-plan{
  border: 1px solid var(--greys-foreground-text-body-copy-2, #3F3F3F);
  color: var(--Core-ACCA-black, #000);
font-family: 'Avenir LT Pro';
font-size: 16px !important;
font-style: normal;
font-weight: 700;
background-color: #fff;
line-height: 24px; /* 150% */
}

.av-font-regular{
  font-family: 'Avenir LT Pro';
}

.edit-plan{
  background: #fff;
  font-size: 16px !important;
  padding-bottom: 0 !important;
  color: black;
}


.border-bottom-dashed{
  border-bottom: 1px dashed black !important;
  box-shadow: none;
  border: 0;
}

.row-input{
  font-size: 25px;
    color: black;
    font-weight: 700;
    padding: 0 15px;
    height: 64px;
    margin-top: 15px;
    width: 250px;
}

.select-1{
  width:    95px;
    height: 54px;
    padding: 0px 18px;
}

.know-more{
  color: var(--Core-ACCA-black, #000);
font-family: 'Avenir LT Pro Medium';
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 40px;
/* text-transform: lowercase; */
}

.text-16{
  font-size: 16px !important;
}

.caecilia{
  font-family: 'Avenir LT Pro';
}




.congrat{
  color: var(--Core-ACCA-black, #000);
font-family: 'Avenir LT Pro';
font-size: 40px;
font-style: normal;
font-weight: 950;
/* text-transform: lowercase; */
}
.congrat-sub{
  color: var(--Core-ACCA-black, #000);
font-family: 'Avenir LT Pro';
font-size: 30px;
font-style: normal;
font-weight: 750;
line-height: 54.5px;
/* text-transform: lowercase; */
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.know-more a{
  color: var(--Core-ACCA-black, #000);
font-family: 'Avenir LT Pro';
font-size: 18px;
font-style: normal;
font-weight: 700;
line-height: 40px;
text-decoration-line: underline;
/* text-transform: lowercase; */
}

.display-66{
  color: var(--Core-ACCA-black, #000);
font-family: 'Avenir LT Pro';
font-size: 26px;
font-style: normal;
font-weight: 900; 
}

.text-26{
  font-size: 26px;
}

.av-font{
  font-family: 'Avenir LT Pro';
}



.bold-input{
  font-size: 21px !important;
  font-weight: 700;
  color: black;
  max-width: 180px;
}

.v-middle{
  vertical-align: middle;
}

input {
  border: 2px solid #DCDCDC;
  background: #FFF;
  padding: 8px;
  border: 2px solid var(--Greys-Backgrounds-DCDCDC, #DCDCDC);
background: var(--Greys-Backgrounds-FAFAFA, #FAFAFA);
}

video::-webkit-media-controls {
  /* display: none; */
}

.bg-gray{
  background: #e5e5e5;
}

.footer-text-2{
  color: #494949;
font-family: "AVENIR LT PRO LIGHT";
font-size: 18px;
font-style: italic;
font-weight: 400 !important;
}

.mb-02{
  margin-bottom: 5px;
}

@media screen and (min-width: 550px) {

  .border-left-2{
    border-left: 2px solid gray;
  }

}

h1 small{
  font-size: 14px !important;
}
@media screen and (max-width: 550px) {

  .mobile-table{
    display: table !important;
  }

  .mobile-table > * {
    display: block !important;
    margin-top: 15px;
  }

  .mobile-table .select-1 {
    display: block !important;
    margin-top: 15px;
  }

  h1{
    font-size: 20px !important;
  }
  
h1 small{
  font-size: 12px !important;
}

.text-26
{
  font-size: 18px;
}

button
{
  font-size: 18px !important;
}

}


.btn-last{
  padding: 5px !important;
  font-size: 16px !important;
}

@media (min-width: 768px) {
  .col-md-7
{
    flex: 0 0 auto;
    width: 55.333% !important;
  }
}

@media (min-width: 768px) {
  .col-md-5
{
    flex: 0 0 auto;
    width: 44.667% !important;
  }
}