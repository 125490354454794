.footer-links{
    display: flex;
align-items: flex-start;
align-content: flex-start;
gap: 12px 24px;
flex: 1 0 0;
flex-wrap: wrap;
}

.footer-links a{
    color: #2D2D2D !important;
font-family: 'AVENIR LT PRO LIGHT';
text-decoration: none;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
}

.social-links{
    display: flex;
justify-content: flex-end;
align-items: center;
gap: 16px;
}